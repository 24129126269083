import React from "react";
// nodejs library that concatenates classes
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/aboutStyle.js";

const useStyles = makeStyles(styles);

export default function AboutSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer className={classes.container}>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          justify={"center"}
          className={classes.content}
        >
          <p className={classes.p}>
            Você já viu uma equipe de comunicação que, ao invés de buscar os
            principais players do mercado, deseja trabalhar com empresas que
            ainda têm muito para desenvolver em termos de Comunicação &
            Marketing, justamente para que todos possam crescer juntos? Essa é a
            equipe PDR.
          </p>
          <p className={classes.p}>
            Não buscamos clientes, mas parceiros no sucesso. A evolução de sua
            comunicação e o seu crescimento, junto a seu público e praças, é o
            nosso maior objetivo. Acreditamos que através de um trabalho bem
            feito, da produção à comunicação e marketing, o tão desejado lucro
            se torna uma consequência natural.
          </p>
          <p className={classes.p}>
            Criada por experientes profissionais de comunicação, na intenção de
            impulsionar um segmento específico do mercado, a PDR se destaca por
            seu potencial criativo e atenção a detalhes que podem fazer a
            diferença.
          </p>
          <p className={classes.p}>
            Sua presença on-line, por exemplo, você sabe qual é a importância
            que ela realmente tem, e tudo que ela pode te entregar, seja através
            de um site otimizado ou por meio de suas redes sociais? Hoje a sua
            atuação na internet tem tudo para ser muito mais relevante do que
            imagina. Acredite, entre em contato com nossa equipe e saiba mais.
          </p>
        </GridItem>
      </GridContainer>
    </div>
  );
}
