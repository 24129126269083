import React from "react";
// nodejs library that concatenates classes
import MediaQuery from "react-responsive";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Facebook from "@material-ui/icons/Facebook";
import Instagram from "@material-ui/icons/Instagram";
import YouTube from "@material-ui/icons/YouTube";
import WhatsApp from "@material-ui/icons/WhatsApp";
import LinkedIn from "@material-ui/icons/LinkedIn";
import Twitter from "@material-ui/icons/Twitter";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import logo from "assets/img/logo.png";

import styles from "assets/jss/material-kit-react/views/landingPageSections/headerStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="right">
        <GridItem xs={12} sm={12} md={4} className={classes.headerSection}>
          <MediaQuery minDeviceWidth={800}>
            <h3 className={classes.title}>
              VOCÊ ESTÁ
              <br /> A POUCOS
              <br /> CLIQUES DE
              <br /> TRANSFORMAR
              <br /> A COMUNICAÇÃO
              <br /> DE SUA MARCA.
            </h3>
          </MediaQuery>
          <MediaQuery maxDeviceWidth={800}>
            <h3 className={classes.titleMobi}>
              VOCÊ ESTÁ
              <br /> A POUCOS
              <br /> CLIQUES DE
              <br /> TRANSFORMAR
              <br /> A COMUNICAÇÃO
              <br /> DE SUA MARCA.
            </h3>
          </MediaQuery>
          <div className={classes.social}>
            <Facebook className={classes.facebook} />
            <Instagram className={classes.instagram} />
            <YouTube className={classes.youtube} />
            <WhatsApp className={classes.whatsapp} />
            <LinkedIn className={classes.linkedin} />
            <Twitter className={classes.twitter} />
          </div>
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={8}
          className={[classes.headerImage, classes.headerSection]}
        >
          <img src={logo} alt="..." className={classes.logoImage} />
        </GridItem>
      </GridContainer>
    </div>
  );
}
