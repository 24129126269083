import { title } from "assets/jss/material-kit-react.js";

const icon = {
  fontSize: "1.5em",
  margin: "0 5px",
  borderRadius: "50%",
  padding: "2px",
  width: "30px",
  height: "30px",
  color: "#fff",
};

const headerStyle = {
  section: {
    padding: "50px 0 20px",
    textAlign: "center",
  },
  headerSection: {
    display: "flex",
    alignSelf: "center",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "right",
    width: "100%",
    color: "#fff",
    flex: 1,
  },
  titleMobi: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center",
    width: "100%",
    color: "#fff",
    flex: 1,
  },
  description: {
    color: "#999",
  },
  headerImage: {
    alignSelf: "flex-start",
  },
  logoImage: {
    width: "100%",
    height: "100%",
  },
  social: {
    flex: 1,
    backgroundColor: "#fff",
    textAlign: "right",
    width: "100%",
    padding: "5px 0 0",
  },
  facebook: {
    // backgroundColor: "#3b5998",
    backgroundColor: "#333",
    ...icon,
  },
  twitter: {
    // backgroundColor: "#1DA1F2",
    backgroundColor: "#333",
    ...icon,
  },
  youtube: {
    // backgroundColor: "#c4302b",
    backgroundColor: "#333",
    ...icon,
  },
  whatsapp: {
    // backgroundColor: "#4FCE5D",
    backgroundColor: "#333",
    ...icon,
  },
  linkedin: {
    // backgroundColor: "#0e76a8",
    backgroundColor: "#333",
    ...icon,
  },
  instagram: {
    backgroundColor: "#333",
    // background: "#f09433",
    // background:
    //   "-moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)",
    // background:
    //   "-webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)",
    // background:
    //   "linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)",
    // filter:
    //   "progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 )",
    ...icon,
  },
};

export default headerStyle;
