import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import HeaderSection from "./Sections/HeaderSection.js";
import TeamSection from "./Sections/TeamSection.js";
import AboutSection from "./Sections/AboutSection.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <HeaderSection />
          <AboutSection />
          <TeamSection />
        </div>
      </div>
    </div>
  );
}
