const aboutStyle = {
  section: {
    display: "flex",
    padding: "30px 0",
    textAlign: "center",
  },
  container: {
    flex: 1,
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
  },
  content: {
    padding: "15px",
    background: "#fff",
    color: "#000",
  },
  p: {
    margin: "15px 0",
    fontSize: "1.2em",
    fontWeight: "400",
  },
};

export default aboutStyle;
