import { cardTitle, title } from "assets/jss/material-kit-react.js";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const icon = {
  fontSize: "1.3em",
  margin: "0 5px",
  borderRadius: "50%",
  padding: "2px",
  width: "30px",
  height: "30px",
  color: "#fff",
};

const teamStyle = {
  section: {
    padding: "20px 0",
    textAlign: "center",
  },
  aboutTeam: {
    textAlign: "right",
    alignContent: "center",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  ...imagesStyle,
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  cardTitle,
  smallTitle: {
    color: "#6c757d",
  },
  description: {
    color: "#999",
  },
  justifyCenter: {
    justifyContent: "center !important",
  },
  social: {
    backgroundColor: "#fff",
    color: "#000",
    padding: "5px 5px",
    lineHeight: "42px",
    marginBottom: "30px",
    alignSelf: "center",
  },
  quemFaz: {
    fontSize: "36px",
    lineHeight: "42px",
    textAlign: "right",
  },
  number: {
    color: "#000",
    height: "30px",
    alignContent: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
  email: {
    color: "#000",
  },
  emailIcon: {
    backgroundColor: "purple",
    verticalAlign: "middle",
    ...icon,
  },
  arrowForward: {
    color: "#000",
    borderRadius: "50%",
    border: "#000 solid 2px",
    verticalAlign: "middle",
    marginLeft: "10px",
  },
  margin5: {
    margin: "5px",
  },
  whatsapp: {
    backgroundColor: "#4FCE5D",
    verticalAlign: "middle",
    ...icon,
  },
  PDR_P: { color: "#099ad1" },
  PDR_D: { color: "#d20179" },
  PDR_R: { color: "#db7427" },
};

export default teamStyle;
