import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import WhatsApp from "@material-ui/icons/WhatsApp";
import Email from "@material-ui/icons/Email";
import ArrowForward from "@material-ui/icons/ArrowForwardIosRounded";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import team1 from "assets/img/faces/thiago.jpeg";
import team2 from "assets/img/faces/pedro.jpeg";
import team3 from "assets/img/faces/daniel.jpeg";
import team4 from "assets/img/faces/heloisa.jpeg";

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} className={classes.aboutTeam}>
          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.social}>
              <a
                className={classes.number}
                href="whatsapp://send?phone=5547997108863"
              >
                <WhatsApp className={classes.whatsapp} />
                <span className={classes.number}>47 99710-8863</span>
                <ArrowForward className={classes.arrowForward} />
              </a>

              <br />
              <a className={classes.email} href="mailto:eu@queropdr.com.br">
                <Email className={classes.emailIcon} />
                <span>eu@queropdr.com.br</span>
                <ArrowForward className={classes.arrowForward} />
              </a>
            </div>
          </GridItem>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} className={classes.quemFaz}>
          <GridItem xs={12} sm={12} md={12}>
            QUEM FAZ
            <br /> SUA COMUNICAÇÃO
            <br /> DE UM JEITO <b className={classes.PDR_P}>P</b>
            <b className={classes.PDR_D}>D</b>
            <b className={classes.PDR_R}>R</b>:
          </GridItem>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <Card plain>
            <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
              <img src={team1} alt="..." className={imageClasses} />
            </GridItem>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card plain>
            <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
              <img src={team2} alt="..." className={imageClasses} />
            </GridItem>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card plain>
            <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
              <img src={team3} alt="..." className={imageClasses} />
            </GridItem>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card plain>
            <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
              <img src={team4} alt="..." className={imageClasses} />
            </GridItem>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
